<template>
  <div>
<!--    {{-->
<!--      (row.current_stage && row.current_stage.current_negotiation && row.current_stage.current_negotiation.user )-->
<!--        ? row.current_stage.current_negotiation.user.full_name-->
<!--        : ''-->
<!--    }}-->

    {{
      (row.current_stage && row.current_stage.current_negotiation )
        ? $companyConfiguration.cardStageNegotiations.getNegotiationNameByType(row.current_stage.current_negotiation.type)
        : ''
    }}

  </div>
</template>

<script>

export default {
  name: "current-stage-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>
.table-router-link {
  text-decoration: none;
}
</style>